
import { popupStatusHide, statePopupStatus } from "@/utils/popup/status";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {
      statePopupStatus,
      popupStatusHide,
    };
  },
});
